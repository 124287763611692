//
// Print Mode
//

// Add .print-content-only class to body element in order to allow printing only the content area
@media print {
    .print-content-only {
        padding: 0 !important;
        background: none !important;

        .wrapper,
        .page,
        .content,
        .container,
        .container-xxl,
        .container-fluid {
            background: none !important;
            padding: 0 !important;
            margin: 0 !important;
        }

        .page-title,
        .aside,
        .sidebar,
        .scrolltop,
        .header,        
        .footer,
        .toolbar,
        .drawer,
        .card-title,
        .form-check-custom,
        .pagination,
        .text-end,
        .btn {
            display: none !important;
        }

        /*h1 {
            font-size: 12pt;
        }

        .header:before {
            content: "\ Vielen herzlichen Dank für das Ausdrucken unseres Artikels. Wir hoffen, dass auch andere Artikel von uns Ihr Interesse wecken können.";
            color: #777 !important;
            font-size: 1em;
            padding-top: 30px;
            text-align: center !important;    
            }

        .entry:after {
            content: "\ All Rights Reserved. (c) 2014 - 2016 ";
            color: #999 !important;
            font-size: 1em;
            padding-top: 30px;
            }*/
    }
}

// uncomment @page to remove date, title, url and page number while printing
/*@page { 
    size: auto;  
    margin: 0mm; 
}*/
